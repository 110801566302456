import axios from "axios";

const devMode = false; // Set to false to use production API

axios.interceptors.request.use(
  (config) => {
    const isLogin = config.url.includes('/login') && config.method === 'post';

    if (devMode && isLogin) {
      // Use localhost for login attempts in dev mode
      config.baseURL = "http://localhost:8074/api/";
      console.warn("Using dev mode for login");
    } else {
      // Extract company for login or use current company for other requests
      const company = isLogin ? config.data.company : localStorage.getItem("currentCompany") || 'default';
      config.baseURL = devMode ? "http://localhost:8074/api/" : `/${company}/api/`;

      // Set token for non-login requests or login requests in production mode
      if (!isLogin || !devMode) {
        const tokenKey = `${company}-token`;
        const token = localStorage.getItem(tokenKey);
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
    }

    console.warn(devMode ? "Using dev mode" : "Using prod mode");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
