import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import Login from "../Login";
import Notifications from './Notifications'; // Assuming Notifications.js is in the same directory

const NavBarContainer = styled(AppBar)({
  background: "#333",
  paddingTop: "20px",
  paddingBottom: "20px",
  marginTop: 0,
  marginBottom: 0,
});

const UserName = styled(Typography)({
  flexGrow: 1,
});

const Navbar = ({ username, onLogout }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("currentCompany") || ""
  );
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    // Extract company names from keys that end with '-username'
    const allKeys = Object.keys(localStorage);
    const companyKeys = allKeys.filter((key) => key.endsWith("-username"));
    const companyNames = companyKeys.map((key) => key.split("-")[0]);
    setCompanies(companyNames);
  }, []);

  const handleCompanyChange = (event) => {
    const newCompany = event.target.value;
    setSelectedCompany(newCompany);
    localStorage.setItem("currentCompany", newCompany);
    window.location.reload();
  };

  const handleLoginSuccess = (data) => {
    setShowLoginPopup(false);
    // Re-fetch companies after successful login
    const allKeys = Object.keys(localStorage);
    const companyKeys = allKeys.filter((key) => key.endsWith("-username"));
    const companyNames = companyKeys.map((key) => key.split("-")[0]);
    setCompanies(companyNames);
    setSelectedCompany(localStorage.getItem("currentCompany") || "");
  };

  const handleAddCompanyClick = () => {
    setShowLoginPopup(true); // Open the login popup
  };

  const CompanyDisplay = () => {
    // Show a dropdown only if there's more than one company
    if (companies.length > 1) {
      return (
        <Select
          value={selectedCompany}
          onChange={handleCompanyChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {companies.map((company) => (
            <MenuItem key={company} value={company}>
              {company}
            </MenuItem>
          ))}
        </Select>
      );
    } else {
      // When only one company, just show it without dropdown
      return (
        <Typography variant="body1">
          {selectedCompany || "No company selected"}
        </Typography>
      );
    }
  };

  return (
    <NavBarContainer position="static">
      <Toolbar>
        <UserName variant="h6">
          {username ? `Welcome back, ${username}!` : "Please log in."}
        </UserName>
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <CompanyDisplay />
          <IconButton color="inherit" onClick={handleAddCompanyClick}>
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
        {username && (
          <>
            <IconButton color="inherit">
              <AccountCircle />
            </IconButton>
            <IconButton color="inherit">
              <Notifications />
            </IconButton>
            <IconButton color="inherit" onClick={onLogout}>
              <LogoutIcon />
            </IconButton>
          </>
        )}
        {showLoginPopup && (
          <Login
            isPopup={true}
            onLoginSuccess={handleLoginSuccess}
            onClose={() => setShowLoginPopup(false)}
          />
        )}
      </Toolbar>
    </NavBarContainer>
  );
};

export default Navbar;
