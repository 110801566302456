import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    const currentCompany = localStorage.getItem('currentCompany');

    useEffect(() => {
        // Retrieve the company name, if available
        const currentCompany = localStorage.getItem('currentCompany');
        
        // Determine the correct keys to use based on the current company
        const tokenKey = currentCompany ? `${currentCompany}-token` : 'token';
        const expirationDateKey = currentCompany ? `${currentCompany}-expirationDate` : 'expirationDate';
        
        // Get the token and expiration date from localStorage
        const token = localStorage.getItem(tokenKey);
        const expirationDate = localStorage.getItem(expirationDateKey);

        if (token && expirationDate) {
            const now = new Date();
            if (new Date(expirationDate) > now) {
                setLoggedIn(true);
            } else {
                // Clear all related data from localStorage
                localStorage.removeItem(currentCompany ? `${currentCompany}-username` : 'username');
                localStorage.removeItem(expirationDateKey);
                localStorage.removeItem(tokenKey);
                if (currentCompany) {
                    localStorage.removeItem('currentCompany');
                }
                navigate('/login');
            }
        } else if (!token) {
            navigate('/login');
        }
    },  [navigate, currentCompany]);

    return loggedIn;
};

export default useAuth;
