import React, { useState } from 'react';
import { Badge, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([
    { id: 1, text: 'This is the pencil of Esther Píscore', read: false },
    { id: 2, text: 'Nothing to see here. Come back later', read: true },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRead = (id) => {
    setNotifications(notifications.map((notification) =>
      notification.id === id ? { ...notification, read: true } : notification
    ));
  };

  const unreadCount = notifications.filter((notification) => !notification.read).length;

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount > 9 ? '9+' : unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.map((notification) => (
          <MenuItem key={notification.id} onClick={() => handleRead(notification.id)}>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" style={{ color: notification.read ? 'grey' : 'red' }} />
            </ListItemIcon>
            <ListItemText primary={notification.text} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Notifications;
