import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Modal,
  Box,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Login = ({ isPopup, onLoginSuccess, onClose }) => {
  const navigate = useNavigate();
  const [user, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState(""); // Company state

  // Function to check if company should be treated as empty
  const isMaxDevMode = () => company.toLowerCase() === "maxdevmode";

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    // Check for a logged-in user based on the currentCompany
    const currentCompany = localStorage.getItem("currentCompany");
    const tokenKey = currentCompany ? `${currentCompany}-token` : "token";
    const expirationDateKey = currentCompany
      ? `${currentCompany}-expirationDate`
      : "expirationDate";

    const token = localStorage.getItem(tokenKey);
    const expirationDate = localStorage.getItem(expirationDateKey);

    if (token && expirationDate && new Date(expirationDate) > new Date()) {
      navigate("/"); // Redirect to home or dashboard route if token is valid
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const md5Password = CryptoJS.MD5(password).toString();
  
    try {
      const response = await axios.post("/login", {
        user,
        password: md5Password,
        company,
      });
  
      if (response.data.token) {
        // Save the login tokens
        const companyTokenKey = `${company}-token`;
        const companyUsernameKey = `${company}-username`;
        const companyExpirationDateKey = `${company}-expirationDate`;
  
        localStorage.setItem(companyTokenKey, response.data.token);
        localStorage.setItem(companyUsernameKey, user);
        const currentDate = new Date();
        const expirationDate = new Date(
          currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
        );
        localStorage.setItem(
          companyExpirationDateKey,
          expirationDate.toISOString()
        );
  
        // Set the currentCompany in localStorage
        localStorage.setItem("currentCompany", company);
  
        // Handle the success scenario (close popup or navigate)
        if (isPopup) {
          onLoginSuccess(response.data);
        } else {
          navigate("/");
        }
      } else {
        // Show an error if login was not successful
        alert(response.data.message);
      }
    } catch (error) {
      alert(
        `Login failed: ${
          error.response ? error.response.data.message : error.message
        }`
      );
    }
  };
  

  const formContent = (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="User"
        value={user}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Company"
        type="text"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        error={company === "" && !isMaxDevMode()}
        helperText={
          company === "" && !isMaxDevMode() ? "Company is required" : " "
        }
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 2 }}
      >
        Login
      </Button>
    </Box>
  );

  if (isPopup) {
    return (
      <Modal open={true} onClose={handleClose}>
        <Box sx={modalStyle}>
          {/* Logo can be included here if needed */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Login
          </Typography>
          {formContent}
        </Box>
      </Modal>
    );
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <img
          src="/anmav7.png"
          alt="Logo"
          style={{ width: "auto", height: "250px" }}
        />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Login
        </Typography>
        {formContent}
      </Container>
    );
  }
};

export default Login;
