import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Landing from './Landing';
import useAuth from './useAuth';

const App = () => {
    const isLoggedIn = useAuth();

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={isLoggedIn ? <Landing /> : <Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                {/* This will catch all other routes */}
                <Route path="/*" element={isLoggedIn ? <Landing /> : <Navigate to="/login" replace />} />
            </Routes>
        </div>
    );
};

export default App;
