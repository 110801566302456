import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";

const Landing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentCompany = localStorage.getItem("currentCompany");
  const usernameKey = currentCompany
    ? `${currentCompany}-username`
    : "username";
  const [username] = useState(localStorage.getItem(usernameKey));
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const dynamicPath = location.pathname.slice(1) || "Home/Home";
  const [currentPage, setCurrentPage] = useState(dynamicPath);

  const handleSidebarToggle = (isExpanded) => {
    setSidebarExpanded(isExpanded);
  };

  const logout = () => {
    // Check if a specific company is set for the current session
    const currentCompany = localStorage.getItem("currentCompany");

    // Determine the correct keys to use based on the current company
    const tokenKey = currentCompany ? `${currentCompany}-token` : "token";
    const expirationDateKey = currentCompany
      ? `${currentCompany}-expirationDate`
      : "expirationDate";
    const usernameKey = currentCompany
      ? `${currentCompany}-username`
      : "username";

    // Clearing JWT and other relevant data based on the current company
    localStorage.removeItem(usernameKey);
    localStorage.removeItem(expirationDateKey);
    localStorage.removeItem(tokenKey);

    // Get all companies
    const allKeys = Object.keys(localStorage);
    const companyKeys = allKeys.filter((key) => key.endsWith("-username"));
    const companyNames = companyKeys.map((key) => key.split("-")[0]);

    // Remove the company identifier if it exists
    if (currentCompany) {
      const remainingCompanies = companyNames.filter(
        (name) => name !== currentCompany
      );
      if (remainingCompanies.length > 0) {
        // Switch to any other company
        localStorage.setItem("currentCompany", remainingCompanies[0]);
      } else {
        // If no other company remains, remove 'currentCompany'
        localStorage.removeItem("currentCompany");
      }
    }

    setIsLoggedOut(true);
  };

  useEffect(() => {
    if (isLoggedOut) {
      navigate("/login");
    }
  }, [isLoggedOut, navigate]);

  useEffect(() => {
    setCurrentPage(location.pathname.slice(1) || "Home/Home");
    setReloadKey((prevKey) => prevKey + 1); // Increment the reload key
  }, [location.pathname]);

  // Dynamic import based on current page
  const PageComponent = lazy(() => import(`./Pages/${currentPage}.js`));

  const MainContentContainer = styled(Container)({
    paddingTop: "80px",
    transition: "margin-left 0.3s", // Smooth transition for margin change
    marginLeft: sidebarExpanded ? "240px" : "72px", // Adjust based on sidebar state
  });

  return (
    <div>
      <Navbar username={username} onLogout={logout} />
      <Sidebar onPageChange={setCurrentPage} onToggleSidebar={handleSidebarToggle} />
      <MainContentContainer>
        {username && (
          <Suspense fallback={<div>Loading...</div>}>
            <PageComponent key={reloadKey} /> {/* Use the reload key */}
          </Suspense>
        )}
      </MainContentContainer>
    </div>
  );
};

export default Landing;
